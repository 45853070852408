/**
* Share
*/

(function(w,d,b) {
	
	w.socialShare = function(SELECTOR, socials) {

		var cached = {
			$container  : d.querySelectorAll(SELECTOR),
			data        : [],
			ui          : [],
			buttons     : []
		};


		for (var i = cached.$container.length - 1; i >= 0; i--) {
			/** Cache  */

			cached.data[i] = {
				id    : cached.$container[i].getAttribute('data-id'),
				title : cached.$container[i].getAttribute('data-title'),
				url   : cached.$container[i].getAttribute('data-url')
			};
			
			cached.ui[i] = {
				$label    : d.createElement('div'),
				$share    : d.createElement('div')
			};
			
			cached.buttons[i] = {
				$facebook : d.createElement('a'),
				$gplus    : d.createElement('a'),
				$twitter  : d.createElement('a'),
			};

			/** Set class */
			
			cached.ui[i].$label.setAttribute('class', 'label');
			cached.ui[i].$share.setAttribute('class', 'share');

			cached.buttons[i].$facebook.setAttribute('data-i',      i                                                                                      );
			cached.buttons[i].$facebook.setAttribute('data-social', 'facebook'                                                                             );
			cached.buttons[i].$facebook.setAttribute('class',       'share-facebook'                                                                       );
			cached.buttons[i].$facebook.setAttribute('href',        'http://www.facebook.com/sharer.php?u=' + cached.data[i].url                           );
			cached.buttons[i].$facebook.setAttribute('_blank',      '_blank'                                                                               );

			cached.buttons[i].$twitter.setAttribute('data-i',       i                                                                                      );
			cached.buttons[i].$twitter.setAttribute('data-social',  'twitter'                                                                              );
			cached.buttons[i].$twitter.setAttribute('class',        'share-twitter'                                                                        );
			cached.buttons[i].$twitter.setAttribute('href',         'http://twitter.com/share?url=' + cached.data[i].url + '&text=' + cached.data[i].title );
			cached.buttons[i].$twitter.setAttribute('_blank',       '_blank'                                                                               );

			cached.buttons[i].$gplus.setAttribute('data-i',         i                                                                                      );
			cached.buttons[i].$gplus.setAttribute('data-social',    'gplus'                                                                                );
			cached.buttons[i].$gplus.setAttribute('class',          'share-gplus'                                                                          );
			cached.buttons[i].$gplus.setAttribute('href',           'https://plus.google.com/share?url=' + cached.data[i].url                              );
			cached.buttons[i].$gplus.setAttribute('_blank',         '_blank'                                                                               );


			/** Populate */

			cached.ui[i].$label.innerHTML = 'Condividi questo articolo';
			cached.ui[i].$share.appendChild(cached.buttons[i].$facebook);
			cached.ui[i].$share.appendChild(cached.buttons[i].$twitter);
			cached.ui[i].$share.appendChild(cached.buttons[i].$gplus);

			/** Append */

			cached.$container[i].appendChild(cached.ui[i].$label);
			cached.$container[i].appendChild(cached.ui[i].$share);

			for (var key in cached.buttons[i]) {
				cached.buttons[i][key].addEventListener('click', sdvSocialButton);
			}

		}

		/**
		 * FUNCTIONS
		 */

		function sdvSocialButton( e ) {
			e.preventDefault();
			var url = this.getAttribute('href');

			if( url ){	
				var day = new Date();
				var id = day.getTime();
				eval("page" + id + " = window.open(url, '" + id + "', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=500,height=500,left = 710,top = 290');");
			}
		}
	};


})(window,document,document.body);