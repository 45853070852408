/**
* Share
*/

(function(w,d,b) {
	
	w.socialShare = function(SELECTOR, socials) {

		var cached = {
			$container  : d.querySelectorAll(SELECTOR),
			data        : [],
			ui          : [],
			buttons     : []
		};


		for (var i = cached.$container.length - 1; i >= 0; i--) {
			/** Cache  */

			cached.data[i] = {
				id    : cached.$container[i].getAttribute('data-id'),
				title : cached.$container[i].getAttribute('data-title'),
				url   : cached.$container[i].getAttribute('data-url')
			};
			
			cached.ui[i] = {
				$label    : d.createElement('div'),
				$share    : d.createElement('div')
			};
			
			cached.buttons[i] = {
				$facebook : d.createElement('a'),
				$gplus    : d.createElement('a'),
				$twitter  : d.createElement('a'),
			};

			/** Set class */
			
			cached.ui[i].$label.setAttribute('class', 'label');
			cached.ui[i].$share.setAttribute('class', 'share');

			cached.buttons[i].$facebook.setAttribute('data-i',      i                                                                                      );
			cached.buttons[i].$facebook.setAttribute('data-social', 'facebook'                                                                             );
			cached.buttons[i].$facebook.setAttribute('class',       'share-facebook'                                                                       );
			cached.buttons[i].$facebook.setAttribute('href',        'http://www.facebook.com/sharer.php?u=' + cached.data[i].url                           );
			cached.buttons[i].$facebook.setAttribute('_blank',      '_blank'                                                                               );

			cached.buttons[i].$twitter.setAttribute('data-i',       i                                                                                      );
			cached.buttons[i].$twitter.setAttribute('data-social',  'twitter'                                                                              );
			cached.buttons[i].$twitter.setAttribute('class',        'share-twitter'                                                                        );
			cached.buttons[i].$twitter.setAttribute('href',         'http://twitter.com/share?url=' + cached.data[i].url + '&text=' + cached.data[i].title );
			cached.buttons[i].$twitter.setAttribute('_blank',       '_blank'                                                                               );

			cached.buttons[i].$gplus.setAttribute('data-i',         i                                                                                      );
			cached.buttons[i].$gplus.setAttribute('data-social',    'gplus'                                                                                );
			cached.buttons[i].$gplus.setAttribute('class',          'share-gplus'                                                                          );
			cached.buttons[i].$gplus.setAttribute('href',           'https://plus.google.com/share?url=' + cached.data[i].url                              );
			cached.buttons[i].$gplus.setAttribute('_blank',         '_blank'                                                                               );


			/** Populate */

			cached.ui[i].$label.innerHTML = 'Condividi questo articolo';
			cached.ui[i].$share.appendChild(cached.buttons[i].$facebook);
			cached.ui[i].$share.appendChild(cached.buttons[i].$twitter);
			cached.ui[i].$share.appendChild(cached.buttons[i].$gplus);

			/** Append */

			cached.$container[i].appendChild(cached.ui[i].$label);
			cached.$container[i].appendChild(cached.ui[i].$share);

			for (var key in cached.buttons[i]) {
				cached.buttons[i][key].addEventListener('click', sdvSocialButton);
			}

		}

		/**
		 * FUNCTIONS
		 */

		function sdvSocialButton( e ) {
			e.preventDefault();
			var url = this.getAttribute('href');

			if( url ){	
				var day = new Date();
				var id = day.getTime();
				eval("page" + id + " = window.open(url, '" + id + "', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=500,height=500,left = 710,top = 290');");
			}
		}
	};


})(window,document,document.body);
/**
 * Canvas
 */

(function() {

	var canvasSlider = {
			$el     : document.querySelector('#slider'),
			$canvas : document.createElement('canvas'),
			data    : {}
		},
		canvasSidebar = {
			$el     : document.querySelector('#sidebar'),
			$canvas : document.createElement('canvas'),
			data    : {}
		},
		canvasFooter = {
			$el     : document.querySelector('#footer'),
			$canvas : document.createElement('canvas'),
			data    : {}
		};

	var canvasMenu = {
		$els    : document.querySelectorAll('#menu>li'),
		$canvas : [],
		ctxs    : [],
		data    : []
	}

	/**
	 * Functions
	 */

	/** Draw Slider */

	var drawSlider = function() {

		/**
		 * SET DATA
		 */
		
		canvasSlider.data = {
			width  : canvasSlider.$el.offsetWidth,
			height : '70',
			top    : canvasSlider.$el.offsetTop,
			left   : canvasSlider.$el.offsetLeft
		}

		canvasSidebar.data = {
			width  : canvasSidebar.$el.offsetWidth,
			height : '20',
			top    : canvasSidebar.$el.offsetTop,
			left   : canvasSidebar.$el.offsetLeft
		}
		
		canvasFooter.data = {
			width  : canvasFooter.$el.offsetWidth,
			height : '50',
			top    : canvasFooter.$el.offsetTop,
			left   : canvasFooter.$el.offsetLeft
		}

		/** Responsive  */

		canvasSlider.data.height  = window.innerWidth > 600 ? canvasSlider.data.height  : canvasSlider.data.height  / 5 * 2;
		canvasSidebar.data.height = window.innerWidth > 600 ? canvasSidebar.data.height : canvasSidebar.data.height / 5 * 2;
		canvasFooter.data.height  = window.innerWidth > 600 ? canvasFooter.data.height  : canvasFooter.data.height  / 5 * 2;

		/** Slider  */

		canvasSlider.$canvas.setAttribute('width', canvasSlider.data.width);
		canvasSlider.$canvas.setAttribute('height', canvasSlider.data.height);

		canvasSlider.ctx.beginPath();
		canvasSlider.ctx.lineTo(0, canvasSlider.data.height);
		canvasSlider.ctx.lineTo(canvasSlider.data.width, canvasSlider.data.height);
		canvasSlider.ctx.lineTo(canvasSlider.data.width, 0);

		canvasSlider.ctx.fillStyle = '#f0f0f0';
		canvasSlider.ctx.fill();

		/** Main */

		canvasSidebar.$canvas.setAttribute('width', canvasSidebar.data.width);
		canvasSidebar.$canvas.setAttribute('height', canvasSidebar.data.height);

		canvasSidebar.ctx.beginPath();
		canvasSidebar.ctx.lineTo(0, 0);
		canvasSidebar.ctx.lineTo(canvasSidebar.data.width, 0);
		canvasSidebar.ctx.lineTo(0, canvasSidebar.data.height);

		canvasSidebar.ctx.fillStyle = '#f0f0f0';
		canvasSidebar.ctx.fill();

		/** Footer */

		canvasFooter.$canvas.setAttribute('width', canvasFooter.data.width);
		canvasFooter.$canvas.setAttribute('height', canvasFooter.data.height);

		canvasFooter.ctx.beginPath();
		canvasFooter.ctx.lineTo(0, 0);
		canvasFooter.ctx.lineTo(canvasFooter.data.width, canvasFooter.data.height);
		canvasFooter.ctx.lineTo(0, canvasFooter.data.height);

		canvasFooter.ctx.fillStyle = '#fff';
		canvasFooter.ctx.fill();
	}


	/** Draw Menu */

	var drawMenu = function(){
		
		for (var i = canvasMenu.$els.length - 1; i >= 0; i--) {
			// canvasMenu.$canvas[i]
			canvasMenu.data[i] = {
				width  : canvasMenu.$els[i].offsetWidth,
				height : canvasMenu.$els[i].offsetHeight,
				top    : canvasMenu.$els[i].offsetTop,
				left   : canvasMenu.$els[i].offsetLeft
			}

			canvasMenu.$canvas[i].setAttribute('width', canvasMenu.data[i].width);
			canvasMenu.$canvas[i].setAttribute('height', canvasMenu.data[i].height);

			canvasMenu.$els[i].appendChild(canvasMenu.$canvas[i]);

			canvasMenu.ctxs[i].beginPath();
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 6);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, 0);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, canvasMenu.data[i].height);
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 7 * 6);

			canvasMenu.ctxs[i].fillStyle = '#00afec';
			canvasMenu.ctxs[i].fill();
		}
	}

	/**
	 * if getContext
	 */

	if (canvasSlider.$canvas.getContext){

		/** Set CTX */
	
		canvasSlider.ctx  = canvasSlider.$canvas.getContext('2d');
		canvasSidebar.ctx = canvasSidebar.$canvas.getContext('2d');
		canvasFooter.ctx  = canvasFooter.$canvas.getContext('2d');

		/** Set ID */
		
		canvasSlider.$canvas.setAttribute('id', 'sliderCanvas');
		canvasSidebar.$canvas.setAttribute('id', 'sidebarCanvas');
		canvasFooter.$canvas.setAttribute('id', 'footerCanvas');

		/** Append */

		canvasSlider.$el.appendChild(canvasSlider.$canvas);
		canvasSidebar.$el.appendChild(canvasSidebar.$canvas);
		canvasFooter.$el.appendChild(canvasFooter.$canvas);

		/** Menu */

		for (var i = canvasMenu.$els.length - 1; i >= 0; i--) {
			canvasMenu.$canvas[i] = document.createElement('canvas');
			canvasMenu.ctxs[i]    = canvasMenu.$canvas[i].getContext('2d');
			
			canvasMenu.$canvas[i].classList.add('menu-canvas');
		}

		/** Draw */
		
		draw();

		/** Draw On resize */
		
		window.addEventListener("resize", draw, false);
		
	}

	/**
	 * Draw Menu
	 */
	var drawMenu = function(){
		
		for (var i = canvasMenu.$els.length - 1; i >= 0; i--) {
			// canvasMenu.$canvas[i]
			canvasMenu.data[i] = {
				width  : canvasMenu.$els[i].offsetWidth,
				height : canvasMenu.$els[i].offsetHeight,
				top    : canvasMenu.$els[i].offsetTop,
				left   : canvasMenu.$els[i].offsetLeft
			}

			canvasMenu.$canvas[i].setAttribute('width', canvasMenu.data[i].width);
			canvasMenu.$canvas[i].setAttribute('height', canvasMenu.data[i].height);

			canvasMenu.$els[i].appendChild(canvasMenu.$canvas[i]);

			canvasMenu.ctxs[i].beginPath();
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 6);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, 0);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, canvasMenu.data[i].height);
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 7 * 6);

			canvasMenu.ctxs[i].fillStyle = '#00afec';
			canvasMenu.ctxs[i].fill();
		}
	}

	/**
	 * Draw All
	 */
	
	function draw () {
		drawMenu();
		drawSlider();
	}

	/**
	 * Return Public method
	 */

	window.canvasDraw = {
		menu : drawMenu
	}

})();
(function(w,d,$body,MENU_ID){
	var $menu = d.getElementById(MENU_ID),
		$button = d.createElement('div')
	

	$button.classList.add('responsiveMenuButton');
	$button.innerHTML = 'Menu';

	$menu.parentNode.insertBefore($button, $menu);
	
	$button.addEventListener('click', function(){
		$body.classList.toggle('responsiveMenuOpen');
		window.canvasDraw.menu();
	});

})(window,document,document.body,'menu');
//////=include _parts/login.js

(function(){
	window.socialShare('div.entry-social-share');
})();