(function(w,d,$body,MENU_ID){
	var $menu = d.getElementById(MENU_ID),
		$button = d.createElement('div')
	

	$button.classList.add('responsiveMenuButton');
	$button.innerHTML = 'Menu';

	$menu.parentNode.insertBefore($button, $menu);
	
	$button.addEventListener('click', function(){
		$body.classList.toggle('responsiveMenuOpen');
		window.canvasDraw.menu();
	});

})(window,document,document.body,'menu');