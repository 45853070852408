/**
 * Canvas
 */

(function() {

	var canvasSlider = {
			$el     : document.querySelector('#slider'),
			$canvas : document.createElement('canvas'),
			data    : {}
		},
		canvasSidebar = {
			$el     : document.querySelector('#sidebar'),
			$canvas : document.createElement('canvas'),
			data    : {}
		},
		canvasFooter = {
			$el     : document.querySelector('#footer'),
			$canvas : document.createElement('canvas'),
			data    : {}
		};

	var canvasMenu = {
		$els    : document.querySelectorAll('#menu>li'),
		$canvas : [],
		ctxs    : [],
		data    : []
	}

	/**
	 * Functions
	 */

	/** Draw Slider */

	var drawSlider = function() {

		/**
		 * SET DATA
		 */
		
		canvasSlider.data = {
			width  : canvasSlider.$el.offsetWidth,
			height : '70',
			top    : canvasSlider.$el.offsetTop,
			left   : canvasSlider.$el.offsetLeft
		}

		canvasSidebar.data = {
			width  : canvasSidebar.$el.offsetWidth,
			height : '20',
			top    : canvasSidebar.$el.offsetTop,
			left   : canvasSidebar.$el.offsetLeft
		}
		
		canvasFooter.data = {
			width  : canvasFooter.$el.offsetWidth,
			height : '50',
			top    : canvasFooter.$el.offsetTop,
			left   : canvasFooter.$el.offsetLeft
		}

		/** Responsive  */

		canvasSlider.data.height  = window.innerWidth > 600 ? canvasSlider.data.height  : canvasSlider.data.height  / 5 * 2;
		canvasSidebar.data.height = window.innerWidth > 600 ? canvasSidebar.data.height : canvasSidebar.data.height / 5 * 2;
		canvasFooter.data.height  = window.innerWidth > 600 ? canvasFooter.data.height  : canvasFooter.data.height  / 5 * 2;

		/** Slider  */

		canvasSlider.$canvas.setAttribute('width', canvasSlider.data.width);
		canvasSlider.$canvas.setAttribute('height', canvasSlider.data.height);

		canvasSlider.ctx.beginPath();
		canvasSlider.ctx.lineTo(0, canvasSlider.data.height);
		canvasSlider.ctx.lineTo(canvasSlider.data.width, canvasSlider.data.height);
		canvasSlider.ctx.lineTo(canvasSlider.data.width, 0);

		canvasSlider.ctx.fillStyle = '#f0f0f0';
		canvasSlider.ctx.fill();

		/** Main */

		canvasSidebar.$canvas.setAttribute('width', canvasSidebar.data.width);
		canvasSidebar.$canvas.setAttribute('height', canvasSidebar.data.height);

		canvasSidebar.ctx.beginPath();
		canvasSidebar.ctx.lineTo(0, 0);
		canvasSidebar.ctx.lineTo(canvasSidebar.data.width, 0);
		canvasSidebar.ctx.lineTo(0, canvasSidebar.data.height);

		canvasSidebar.ctx.fillStyle = '#f0f0f0';
		canvasSidebar.ctx.fill();

		/** Footer */

		canvasFooter.$canvas.setAttribute('width', canvasFooter.data.width);
		canvasFooter.$canvas.setAttribute('height', canvasFooter.data.height);

		canvasFooter.ctx.beginPath();
		canvasFooter.ctx.lineTo(0, 0);
		canvasFooter.ctx.lineTo(canvasFooter.data.width, canvasFooter.data.height);
		canvasFooter.ctx.lineTo(0, canvasFooter.data.height);

		canvasFooter.ctx.fillStyle = '#fff';
		canvasFooter.ctx.fill();
	}


	/** Draw Menu */

	var drawMenu = function(){
		
		for (var i = canvasMenu.$els.length - 1; i >= 0; i--) {
			// canvasMenu.$canvas[i]
			canvasMenu.data[i] = {
				width  : canvasMenu.$els[i].offsetWidth,
				height : canvasMenu.$els[i].offsetHeight,
				top    : canvasMenu.$els[i].offsetTop,
				left   : canvasMenu.$els[i].offsetLeft
			}

			canvasMenu.$canvas[i].setAttribute('width', canvasMenu.data[i].width);
			canvasMenu.$canvas[i].setAttribute('height', canvasMenu.data[i].height);

			canvasMenu.$els[i].appendChild(canvasMenu.$canvas[i]);

			canvasMenu.ctxs[i].beginPath();
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 6);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, 0);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, canvasMenu.data[i].height);
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 7 * 6);

			canvasMenu.ctxs[i].fillStyle = '#00afec';
			canvasMenu.ctxs[i].fill();
		}
	}

	/**
	 * if getContext
	 */

	if (canvasSlider.$canvas.getContext){

		/** Set CTX */
	
		canvasSlider.ctx  = canvasSlider.$canvas.getContext('2d');
		canvasSidebar.ctx = canvasSidebar.$canvas.getContext('2d');
		canvasFooter.ctx  = canvasFooter.$canvas.getContext('2d');

		/** Set ID */
		
		canvasSlider.$canvas.setAttribute('id', 'sliderCanvas');
		canvasSidebar.$canvas.setAttribute('id', 'sidebarCanvas');
		canvasFooter.$canvas.setAttribute('id', 'footerCanvas');

		/** Append */

		canvasSlider.$el.appendChild(canvasSlider.$canvas);
		canvasSidebar.$el.appendChild(canvasSidebar.$canvas);
		canvasFooter.$el.appendChild(canvasFooter.$canvas);

		/** Menu */

		for (var i = canvasMenu.$els.length - 1; i >= 0; i--) {
			canvasMenu.$canvas[i] = document.createElement('canvas');
			canvasMenu.ctxs[i]    = canvasMenu.$canvas[i].getContext('2d');
			
			canvasMenu.$canvas[i].classList.add('menu-canvas');
		}

		/** Draw */
		
		draw();

		/** Draw On resize */
		
		window.addEventListener("resize", draw, false);
		
	}

	/**
	 * Draw Menu
	 */
	var drawMenu = function(){
		
		for (var i = canvasMenu.$els.length - 1; i >= 0; i--) {
			// canvasMenu.$canvas[i]
			canvasMenu.data[i] = {
				width  : canvasMenu.$els[i].offsetWidth,
				height : canvasMenu.$els[i].offsetHeight,
				top    : canvasMenu.$els[i].offsetTop,
				left   : canvasMenu.$els[i].offsetLeft
			}

			canvasMenu.$canvas[i].setAttribute('width', canvasMenu.data[i].width);
			canvasMenu.$canvas[i].setAttribute('height', canvasMenu.data[i].height);

			canvasMenu.$els[i].appendChild(canvasMenu.$canvas[i]);

			canvasMenu.ctxs[i].beginPath();
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 6);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, 0);
			canvasMenu.ctxs[i].lineTo(canvasMenu.data[i].width, canvasMenu.data[i].height);
			canvasMenu.ctxs[i].lineTo(0, canvasMenu.data[i].height / 7 * 6);

			canvasMenu.ctxs[i].fillStyle = '#00afec';
			canvasMenu.ctxs[i].fill();
		}
	}

	/**
	 * Draw All
	 */
	
	function draw () {
		drawMenu();
		drawSlider();
	}

	/**
	 * Return Public method
	 */

	window.canvasDraw = {
		menu : drawMenu
	}

})();